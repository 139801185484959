import classNames from "classnames"
import { top5 } from "components/editor/fonts"
import { useShop } from "hooks/firestore/simple/useShop"
import Link from "next/link"
import { LandingpageBlock } from "types/firestore/shop"

export default function LandingpageBlocks() {
  const shop = useShop()

  if (!shop?.landingpageBlocks) return null

  return (
    <>
      {shop.landingpageBlocks.map(block => (
        <LandingpageBlockComponent key={block.id} block={block} />
      ))}
    </>
  )
}

function LandingpageBlockComponent({ block }: { block: LandingpageBlock }) {
  const image = block.image
  const text = block.text

  if (!image && !text) return null

  //   Load fonts in background
  top5.forEach(font => {
    font.load().then(loaded => {
      loaded.loadFont()
    })
  })

  return (
    <section className="tw-container tw-mx-auto tw-px-4 tw-py-12 ">
      <div
        className={classNames("tw-flex tw-flex-col lg:tw-flex-row tw-gap-12 ", {
          "lg:tw-flex-row-reverse": block.direction === "imageRight",
          "!tw-flex-col !tw-items-center": !image || !text,
        })}
      >
        {image && (
          <div className="tw-flex tw-flex-col tw-items-center">
            <img
              src={image?.url}
              alt=""
              className={classNames(
                "tw-object-cover tw-rounded-2xl tw-flex-1 lg:tw-max-w-[600px] xl:tw-max-w-[800px]",
                text ? "tw-w-full" : "tw-w-auto"
              )}
            />
            {!text && block.button?.active && (
              <Link href={block.button.link || ""}>
                <a
                  style={{
                    background: "var(--gradient)",
                    color: "var(--gradientText)",
                  }}
                  className="tw-mt-4 tw-no-underline tw-text-lg tw-rounded-lg tw-px-6 tw-py-3 aria-disabled:tw-cursor-not-allowed aria-disabled:tw-bg-gray-400 aria-disabled:tw-text-gray-700 md:tw-w-fit tw-text-center"
                >
                  {block.button.text}
                </a>
              </Link>
            )}
          </div>
        )}

        {text && (
          <div
            className={classNames(
              "tw-flex tw-flex-col tw-justify-center tw-flex-1",
              image ? "tw-flex-1 tw-w-full" : "tw-w-auto"
            )}
          >
            <p className="tw-prose tw-mb-0 tw-prose-base" dangerouslySetInnerHTML={{ __html: text }} />

            {block.button?.active && (
              <Link href={block.button.link || ""}>
                <a
                  style={{
                    background: "var(--gradient)",
                    color: "var(--gradientText)",
                  }}
                  className="tw-mt-4 tw-no-underline tw-text-lg tw-rounded-lg tw-px-6 tw-py-3 aria-disabled:tw-cursor-not-allowed aria-disabled:tw-bg-gray-400 aria-disabled:tw-text-gray-700 md:tw-w-fit tw-text-center"
                >
                  {block.button.text}
                </a>
              </Link>
            )}
          </div>
        )}
      </div>
    </section>
  )
}
