import { useShop } from "hooks/firestore/simple/useShop"
import { useState } from "react"

export default function Popup() {
  const shop = useShop()

  const [open, setOpen] = useState(true)

  const isActive = shop?.landingpage?.specialElements?.popup?.active || false

  const popupTitle = shop?.landingpage?.specialElements?.popup?.title
  const popupDescription = shop?.landingpage?.specialElements?.popup?.description

  if (!shop) return null
  if (!isActive || !open) return null

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
      <div className="tw-bg-white tw-p-8 tw-rounded-lg tw-shadow-lg tw-max-w-md tw-w-full tw-relative">
        <button
          onClick={handleClose}
          className="tw-absolute tw-top-2 tw-right-2 tw-text-gray-500 hover:tw-text-gray-700 tw-text-xl tw-font-bold"
        >
          &times;
        </button>
        <h2 className="tw-text-2xl tw-font-bold tw-mb-4">{popupTitle}</h2>
        <p className="tw-mb-6">{popupDescription}</p>
      </div>
    </div>
  )
}
