import { useDelayedDocument } from "hooks/firestore/core/useDelayedDocument"
import { getBasketRef } from "hooks/firestore/getRefs"
import { useShopId } from "hooks/localstate/context/useShopId"
import { useBasketId } from "hooks/localstate/localstorage/useBasketId"
import Basket from "types/firestore/basket"

export function useDelayedBasket() {
  const shopId = useShopId()
  const basketId = useBasketId()
  const ref = getBasketRef(shopId, basketId)
  const { delayedDocument: basket, updateDocument: updateBasket, commit } = useDelayedDocument<Basket>(ref)
  return { basket, updateBasket, commit }
}
