import { useShop } from "hooks/firestore/simple/useShop"
import gastromiaLogo from "images/gastromia_logo.png"
import Image from "next/image"
import Link from "next/link"

export default function Footer() {
  const shop = useShop()

  if (!shop) return

  const baseUrl = shop.domain ? "/legal" : `/${shop.shorthand}/legal`

  return (
    <footer className="tw-bg-white tw-py-10 tw-flex tw-items-center tw-justify-center">
      <div className="tw-container tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-between tw-px-5">
        <p className="tw-text-xl tw-font-bold tw-m-0">{shop.name}</p>
        <Image width={150} height={80} src={gastromiaLogo} alt="GASTROMIA Logo" className="tw-object-contain" />
        {/* Link to legal pages */}
        <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-between tw-gap-x-2">
          <Link href={`${baseUrl}/impressum`}>
            <a className="tw-text-base">Impressum</a>
          </Link>
          <Link href={`${baseUrl}/datenschutz`}>
            <a className="tw-text-base">Datenschutz</a>
          </Link>
          <Link href={`${baseUrl}/agb`}>
            <a className="tw-text-base">AGB</a>
          </Link>
        </div>
      </div>
    </footer>
  )
}
