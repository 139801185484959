import type { GoogleFont } from "@remotion/google-fonts"

export const top5 = [
  {
    family: "Inter",
    // eslint-disable-next-line import/extensions
    load: () => import("@remotion/google-fonts/Inter") as Promise<GoogleFont>,
  },
  {
    family: "Roboto",
    // eslint-disable-next-line import/extensions
    load: () => import("@remotion/google-fonts/Roboto") as Promise<GoogleFont>,
  },
  {
    family: "Open Sans",
    // eslint-disable-next-line import/extensions
    load: () => import("@remotion/google-fonts/OpenSans") as Promise<GoogleFont>,
  },
  {
    family: "Lato",
    // eslint-disable-next-line import/extensions
    load: () => import("@remotion/google-fonts/Lato") as Promise<GoogleFont>,
  },
  {
    family: "Montserrat",
    // eslint-disable-next-line import/extensions
    load: () => import("@remotion/google-fonts/Montserrat") as Promise<GoogleFont>,
  },
]
