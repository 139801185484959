import { DeliveryOption, PickupOption } from "types/firestore/distributionOption"
import ZonedOpeningHours from "utils/ZonedOpeningHours"

export const getNextOpenDates = (
  distributionOption: DeliveryOption | PickupOption,
  openTimes: string,
  orderTimeframe: { start: Date | null; end: Date | null },
  timezone = "Europe/Berlin"
) => {
  const oh = new ZonedOpeningHours(openTimes, timezone)

  if (!orderTimeframe.start || !orderTimeframe.end) {
    return undefined
  }

  let nextOpenDate: Date | undefined = orderTimeframe.start
  while (nextOpenDate && nextOpenDate <= orderTimeframe.end && !oh.getState(nextOpenDate)) {
    nextOpenDate = oh.getNextChange(nextOpenDate, orderTimeframe.end)
  }

  if (!nextOpenDate) {
    return undefined
  }
  if (distributionOption.timeMode === "range") {
    const interval = oh.getMatchingIntervals(nextOpenDate)[0] as [Date, Date, boolean, string | undefined] | undefined
    return interval ? ([interval[0], interval[1]] as [Date, Date]) : undefined
  }
  return [nextOpenDate, nextOpenDate] as [Date, Date]
}
