import classNames from "classnames"
import { useShop } from "hooks/firestore/simple/useShop"
import gastromiaLogo from "images/gastromia_logo.png"
import Image from "next/image"
import Link from "next/link"
import { Phone } from "styled-icons/boxicons-solid"

export const navbarPaddingInRem = 2
export const navbarHeightInRem = 6 + navbarPaddingInRem
export const bannerHeightInRem = 3

export default function Navbar() {
  const shop = useShop()

  if (!shop) return null

  const shopImageUrl = typeof shop.image === "string" ? shop.image : shop.image?.url
  const navbarBackgroundColor = shop.landingpage?.navbar?.backgroundColor || "white"
  const navbarTextColor = shop.landingpage?.navbar?.textColor || "black"

  return (
    <div>
      {shop.landingpage?.specialElements?.banner?.active && (
        <div
          style={{ height: `${bannerHeightInRem}rem`, padding: `0 ${navbarPaddingInRem}rem` }}
          className="tw-bg-black tw-w-full tw-z-50 tw-flex tw-flex-row tw-items-center tw-justify-center"
        >
          <p className="tw-text-white tw-text-lg tw-m-0">{shop.landingpage?.specialElements?.banner?.text}</p>
        </div>
      )}
      <div
        style={{ height: `${navbarHeightInRem}rem`, backgroundColor: navbarBackgroundColor }}
        className="tw-relative tw-w-full tw-z-50 tw-flex tw-flex-row tw-items-center sm:tw-justify-center tw-overflow-hidden tw-py-4"
      >
        <div className="tw-max-w-5xl tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-h-full tw-px-5">
          <div className="tw-flex tw-flex-row tw-items-center tw-w-full tw-gap-x-10">
            <Image
              className="tw-object-contain"
              width={100}
              height={100}
              layout="fixed"
              src={shopImageUrl || gastromiaLogo}
              alt="Gastromia Logo"
            />
            {shop.contact.phone && (
              <Link passHref href={`tel:${shop.contact.phone}`}>
                <span
                  style={{ color: navbarTextColor }}
                  className="tw-ml-auto sm:tw-ml-0 tw-no-underline tw-text-xl tw-cursor-pointer"
                >
                  <Phone size={28} className="tw-mr-2" />
                  {shop.contact.phone}
                </span>
              </Link>
            )}
          </div>

          <Link href="/bestellung">
            <a
              style={{
                background: "var(--gradient)",
                color: "var(--gradientText)",
              }}
              className={classNames(
                "tw-hidden sm:tw-block tw-no-underline tw-text-lg visited:tw-text-black hover:tw-text-black tw-rounded-lg tw-px-6 tw-py-3"
              )}
            >
              Bestellen
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}
