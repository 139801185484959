import { useShop } from "hooks/firestore/simple/useShop"

export default function OpeningHoursSection() {
  const shop = useShop()

  if (!shop) return

  if (!shop.landingpage?.openingHours) return

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-24">
      <h2 className="tw-mb-8">Öffnungszeiten</h2>
      <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-max-w-md">
        <div>
          <p>
            <strong>Montag:</strong>
          </p>
          <p>
            <strong>Dienstag:</strong>
          </p>
          <p>
            <strong>Mittwoch:</strong>
          </p>
          <p>
            <strong>Donnerstag:</strong>
          </p>
          <p>
            <strong>Freitag:</strong>
          </p>
          <p>
            <strong>Samstag:</strong>
          </p>
          <p>
            <strong>Sonntag:</strong>
          </p>
        </div>
        <div>
          <p>{shop.landingpage.openingHours.monday || "–"}</p>
          <p>{shop.landingpage.openingHours.tuesday || "–"}</p>
          <p>{shop.landingpage.openingHours.wednesday || "–"}</p>
          <p>{shop.landingpage.openingHours.thursday || "–"}</p>
          <p>{shop.landingpage.openingHours.friday || "–"}</p>
          <p>{shop.landingpage.openingHours.saturday || "–"}</p>
          <p>{shop.landingpage.openingHours.sunday || "–"}</p>
        </div>
      </div>
    </div>
  )
}
