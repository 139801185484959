import { getDocument, useDocument } from "hooks/firestore/core/useDocument"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getShopRef } from "hooks/firestore/getRefs"
import { useShopId } from "hooks/localstate/context/useShopId"
import Shop from "types/firestore/shop"

// ts-prune-ignore-next
export function useShop(): WithRef<Shop> | undefined
export function useShop(shopId: string): WithRef<Shop> | undefined
export function useShop(shopId?: string) {
  // TypeScript function overloads should prevent conditional calling of the hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realShopId = shopId || useShopId()
  const { document } = useDocument(getShopRef(realShopId))
  return document
}

export function useShopWithLoadingState(): { shop: WithRef<Shop> | undefined; loading: boolean }
export function useShopWithLoadingState(shopId: string): { shop: WithRef<Shop> | undefined; loading: boolean }
export function useShopWithLoadingState(shopId?: string) {
  // TypeScript function overloads should prevent conditional calling of the hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realShopId = shopId || useShopId()
  const { document, loading } = useDocument(getShopRef(realShopId))
  return { shop: document, loading }
}

// ts-prune-ignore-next
export async function getShop(shopId: string) {
  return await getDocument(getShopRef(shopId))
}
