import booleanPointInPolygon from "@turf/boolean-point-in-polygon"
import { point } from "@turf/helpers"
import { GeoJSON } from "geojson"
import { getResolveAddress } from "hooks/misc/useResolveAddress"
import { BasketAddress } from "types/firestore/basket"

export default async function isLocationInGeoJSON(address: BasketAddress, geoJSON: GeoJSON | string) {
  const coordinates =
    address.latitude && address.longitude
      ? { latitude: address.latitude, longitude: address.longitude }
      : address.address && address.city && address.zipCode
        ? await getResolveAddress({
            street: address.address,
            city: address.city,
            postalcode: address.zipCode,
            country: address.country,
          })
        : undefined

  if (!coordinates) {
    const err = new Error("Failed to get coordinates for address")
    throw err
  }

  const realGeoJSON = typeof geoJSON === "object" ? geoJSON : JSON.parse(geoJSON) || {}
  if (!realGeoJSON || Object.entries(realGeoJSON).length === 0 || realGeoJSON.type !== "FeatureCollection") {
    const err = new Error("Missing or corrupt geoJSON, please provide a valid geoJSON object.")
    throw err
  }

  let foundZoneId: string | null = null

  const customerPoint = point([coordinates.longitude, coordinates.latitude])

  for (const feature of realGeoJSON.features) {
    if (booleanPointInPolygon(customerPoint, feature)) {
      if (feature.properties?.isDisabled) {
        return false
      }
      foundZoneId = feature.properties?.zoneId
    }
  }

  if (!foundZoneId) {
    return false
  }

  return true
}
