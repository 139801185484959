import { useShop } from "hooks/firestore/simple/useShop"
import Link from "next/link"

export default function SectionBelowHero() {
  const shop = useShop()

  if (!shop) return null

  const image = shop.landingpage?.sectionBelowHero?.image
  const title = shop.landingpage?.sectionBelowHero?.title
  const description = shop.landingpage?.sectionBelowHero?.description
  const descriptionFontSizeInRem = shop.landingpage?.sectionBelowHero?.descriptionFontSizeInRem

  if (!image || !title || !description) return null

  return (
    <section className="tw-container tw-mx-auto tw-px-4 tw-py-24 md:tw-py-56">
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-12">
        <img src={image.url} className="tw-w-full tw-object-cover tw-rounded-2xl" />

        <div className="tw-flex tw-flex-col tw-justify-center">
          <h2 className="tw-text-3xl tw-font-bold tw-mb-4">{title}</h2>
          <p
            className="tw-prose tw-mb-0"
            style={{ fontSize: `${descriptionFontSizeInRem || 1.125}rem` }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <Link href="/bestellung">
            <a
              style={{
                background: "var(--gradient)",
                color: "var(--gradientText)",
              }}
              className="tw-mt-8 tw-no-underline tw-text-lg tw-rounded-lg tw-px-6 tw-py-3 aria-disabled:tw-cursor-not-allowed aria-disabled:tw-bg-gray-400 aria-disabled:tw-text-gray-700 md:tw-w-fit tw-text-center"
            >
              Jetzt bestellen
            </a>
          </Link>
        </div>
      </div>
    </section>
  )
}
