import { useShop } from "hooks/firestore/simple/useShop"

export default function FreeSection() {
  const shop = useShop()
  const text = shop?.landingpage?.freeTextSection?.text || ""

  if (!text) {
    return null
  }

  return (
    <div
      className="tw-container tw-mx-auto tw-px-4 tw-pb-24 tw-prose tw-prose-lg prose-headings:tw-text-[var(--secondary)] tw-text-balance"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}
